@import "../scss/vars.scss";
.x-panel {
  position: fixed;
  left: 0;
  top: 0;
  @include bgSolid;
  width: 100vw;
  z-index: 99;
  height: 100vh;
  box-shadow: 0 0 20px 0px rgba(black, 0.4);
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  transition: max-width 0.2s ease-in;

  .x-panel-close {
    z-index: 99999;
    position: fixed;
    right: 10px;
    top: 10px;
  }

  &.right {
    left: unset;
    right: 0;
    top: 0;

    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    .x-panel-close {
      svg {
        transform: scaleX(-1);
      }
    }
  }
}
