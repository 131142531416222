@import "../scss/vars";

.dna-dark {
  .x-task-master {
    .x-t-grid {
      background-color: rgba($bgDark, 0.8);
    }
  }
}
.x-task-master {
  max-width: 1000px;
  height: calc(100vh - 80px);
  margin: 0 auto;
  position: relative;

  .x-tm-intro {
    padding: 30px;
    margin-left: 100px;
  }

  .x-t-grid {
    height: calc(100vh - 144px);
    background-color: rgba(white, 0.8);
    backdrop-filter: blur(3px);
    margin-top: 10px;
    .ag-row-odd {
      @include bg05;
    }
    .ag-floating-top {
      border-radius: 5px;
      border: 1px solid rgba($dark, 0.5);
      box-shadow: 0 2px 3px 0px rgba(black, 0.2);
    }

    .ag-row {
      border: 1px solid rgba(gray, 0.25);
      cursor: pointer;
      border-radius: 5px;

      &:not(:last-child) {
        border-bottom: 0px;
      }

      &:before {
        background: url(/img/noisy-light.png);
      }
    }

    .ag-body-horizontal-scroll {
      display: none;
    }

    .ag-header-cell {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        left: 0 !important;
      }
    }
    .ag-header-cell-label {
      text-transform: none;
      font-size: 13px;
    }
    .ag-icon-desc {
      transform: scale(0.8) translateY(1px);
      color: red;
    }
    .ag-cell {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: normal;
      white-space: normal;
      text-overflow: unset;
      padding-top: 13px;
      &.ag-cell-focus {
        border-color: transparent !important;
      }

      .x-tm-ai-title {
        margin-bottom: 6px;
      }

      svg {
        transform: translateY(3px);
        font-size: 16px;
      }

      .dna-progress-bar-x {
        min-height: 9px;
        max-height: 9px;
        margin-top: 10px;
        margin-bottom: 12px;
      }

      .dna-pill-box {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 18px;
        line-height: 28px;
        font-size: 12px;
        letter-spacing: 0.3;
        align-self: flex-start;
        display: flex !important;
        align-items: center;
        margin-bottom: 4px;
        svg {
          margin-right: 3px;
          transform: translateY(0px) !important;
          font-size: 15px;
        }
      }
    }
  }
}
