/** Magda
============================================================= */

@font-face {
  font-family: "MessinaSans";
  font-style: normal;
  font-weight: 100;
  src: url("/fonts/Messina/new/MessinaSans-Light.woff2") format("woff2");
}
@font-face {
  font-family: "MessinaSans";
  font-style: normal;
  font-weight: 200;
  src: url("/fonts/Messina/new/MessinaSans-Light.woff2") format("woff2");
}

@font-face {
  font-family: "MessinaSans";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Messina/new/MessinaSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "MessinaSans";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/Messina/new/MessinaSans-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "MessinaSans";
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/Messina/new/MessinaSans-Black.woff2") format("woff2");
}

/** Magda
============================================================= */

@font-face {
  font-family: "Magda";
  src: url("/fonts/Magda/MagdaPlainRegular.otf");
}
@font-face {
  font-family: "Magda Clean";
  src: url("/fonts/Magda/MagdaClean.ttf");
}
